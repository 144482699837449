exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-board-tsx": () => import("./../../../src/pages/board.tsx" /* webpackChunkName: "component---src-pages-board-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internships-tsx": () => import("./../../../src/pages/internships.tsx" /* webpackChunkName: "component---src-pages-internships-tsx" */),
  "component---src-pages-management-acceleration-program-tsx": () => import("./../../../src/pages/management-acceleration-program.tsx" /* webpackChunkName: "component---src-pages-management-acceleration-program-tsx" */),
  "component---src-pages-management-trainee-projects-tsx": () => import("./../../../src/pages/management-trainee-projects.tsx" /* webpackChunkName: "component---src-pages-management-trainee-projects-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */),
  "component---src-templates-company-index-tsx": () => import("./../../../src/templates/company/index.tsx" /* webpackChunkName: "component---src-templates-company-index-tsx" */),
  "component---src-templates-info-index-tsx": () => import("./../../../src/templates/info/index.tsx" /* webpackChunkName: "component---src-templates-info-index-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news/index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */)
}

